import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.openDialog()}}},[_c(VIcon,{attrs:{"color":"gray","left":"","small":""}},[_vm._v("mdi-file-document-outline")]),_vm._v(" "+_vm._s(_vm.$t('general.titles.nomenclators.downloadTemplate').toUpperCase())+" ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('general.titles.nomenclators.downloadTemplate'))+" - "+_vm._s(_vm.$t('modules.name.Location'))+" "),_c(VSpacer),_c(VBtn,{attrs:{"text":"","icon":"","fab":""},on:{"click":_vm.dialogTemplateCSVClose}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAlert,{staticClass:"mt-2",attrs:{"border":"left","color":"orange","dense":"","icon":"mdi-alert-outline","text":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('general.titles.warningUploadTemplate'))+" ")]),_c(VDivider),_c(VList,[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('general.titles.nomenclators.downloadTemplate'))+" ")]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.$t('general.titles.uploadTemplateNote'))+" ")])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"secondary","outlined":"","small":"","tile":""},on:{"click":_vm.fetchTemplate}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.generate'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }