<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("locations.titles.locations") }}
        </p>
      </v-list-item-content>
    </v-list-item>

    <v-data-table
        :headers='headers(selectedColumns)'
        :items="locations"
        :loading="loading"
        v-model="selectedItems"
        :show-select="showCheckBox"
        :options.sync="options"
        :server-items-length="totalFromServer"
        :no-results-text="$t('general.ui.not_search_found')"
        :no-data-text="$t('general.ui.not_data_found')"
        :loading-text="$t('general.notification.loadData')"
        :footer-props="{
          ...rowsPerPageItemsOptions,
          itemsPerPageText: $t('general.table.itemsPerPageText'),
          pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
        }"
    >
      <template v-slot:top>
        <v-row class="d-flex justify-space-between align-center">
          <v-col lg="4" md="4" sm="6" xl="4" cols="6">
            <v-text-field onkeypress="return (event.charCode != 34)"
                          v-on:keyup.86="replaceSearch" v-on:keyup.ctrl.86="replaceSearch"
                          dense
                          filled
                          rounded
                          color="secondary"
                          v-model="search"
                          append-icon="mdi-magnify"
                          :label="$t('general.titles.filter')"
                          :clearable="true"
                          single-line
                          hide-details
                          @keypress.enter="searchForText"
                          @click:clear="searchAll"
                          @click:append="searchForText"
            />
          </v-col>
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-btn
                small
                tile
                :elevation="0"
                color="#d8d8d896"
                style="border-radius: 1.5em;"
                @click="checkBoxShow"
                class="mr-1"
                :disabled="!(locations && locations.length > 0)"
            >
              <span v-if="!showCheckBox">
                <v-icon small color="#6D6D6D" left
                >mdi-checkbox-multiple-marked-outline</v-icon
                >
                <span style="color: #6D6D6D">{{
                    $t("general.buttons.checksBoxEnable")
                  }}</span>
              </span>
              <span v-else>
                <v-icon small color="#6D6D6D" left
                >mdi-checkbox-multiple-marked</v-icon
                >
                <span>{{ $t("general.buttons.checksBoxDisable") }}</span>
              </span>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col lg="4" md="4" sm="8" xl="4">
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-badge
                bordered
                color="secondary"
                overlap
                :content="cantFilters"
                left
                :value="cantFilters"
              >
                <v-btn
                  small
                  tile
                  :elevation="0"
                  color="#d8d8d896"
                  style="border-radius: 1.5em 0 0 1.5em"
                  @click="openFilters"
                  class="mr-1"
                >
                  <v-icon small color="#6D6D6D" left>mdi-filter-variant</v-icon>
                  <span style="color: #6d6d6d">{{
                      $t('general.buttons.filter')
                    }}</span>
                </v-btn>
              </v-badge>
              <!--<v-menu
                :absolute="false"
                :open-on-hover="false"
                :close-on-click="true"
                :close-on-content-click="false"
                :offset-y="true"
                v-model="menu"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    tile
                    :elevation="0"
                    color="#d8d8d896"
                    class="mr-1"
                    @click="menu = !menu"
                  >
                    <v-icon small color="#6D6D6D" left
                    >mdi-cog</v-icon
                    >
                    <span style="color: #6d6d6d">{{ $t('general.buttons.options') }}</span>
                  </v-btn>
                </template>
                <v-list class="pa-3">
                  <v-list-item
                    dense
                    class="d-block py-2"
                  >
                    <v-list-item-title class="mb-1">
                        <span style="color: #6d6d6d">{{
                            $t('records.buttoms.export')
                          }}</span>
                    </v-list-item-title>
                    <div class="d-block">
                      &lt;!&ndash;        Export CSV&ndash;&gt;
                      <v-btn text
                             @click="exportCSVLocations"
                             v-if="check([{ domain: 'Location', permisions: ['Write'] }])"
                             :loading="loadingExport"
                      >
                        <v-icon color='gray' left small>mdi-download-multiple</v-icon>
                        {{ $t("general.buttons.export") }}
                        <template v-slot:loader>
                          <v-progress-circular
                            style="position: absolute;
                                   margin-left: -3px;
                                   height: 15px;"
                            color="secondary accent-4"
                            indeterminate
                            rounded
                          />
                        </template>
                      </v-btn>
                    </div>
                  </v-list-item>
                  <v-list-item
                    dense
                    class="d-block py-2"
                  >
                    <v-list-item-title>
                        <span style="color: #6d6d6d">{{
                            $t('records.buttoms.import')
                          }}</span>
                    </v-list-item-title>
                    <div class="d-block">
                      &lt;!&ndash;        Import CSV&ndash;&gt;
                      <DownloadCSVTemplateComponent v-on:closeMenu="(menu = $event)" module="locations" />
                      &lt;!&ndash;        Template CSV&ndash;&gt;
                      <ImportCSVComponent v-on:closeMenu="(menu = $event)" module="locations" />
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>-->

              <more-options :items="selectedItems" :filters="computedFilters" string-template="location" string-translate="locations"/>

              <v-btn
                  class="ml-1"
                  small
                  tile
                  :elevation="0"
                  color="#d8d8d896"
                  style="border-radius: 0 1.5em 1.5em 0;"
                  @click="mgmtLocation"
                  v-if="check([{ domain: 'Location', permisions: ['Write'] }])"
              >
                <v-icon small color="#6D6D6D" left>mdi-plus</v-icon>
                <span style="color:#6D6D6D">{{
                    $t("general.buttons.new")
                  }}</span>
              </v-btn>
            </v-toolbar>
          </v-col>
        </v-row>
        <!--FILTERS TOOLBAR *********************************************************************-->
        <v-toolbar
          v-if="filtersChips && filtersChips.length > 0"
          class="mb-3"
          flat
          style="background-color:#f4f2f2; border-radius: 0.3em"
        >
          <v-row style="display: contents">
            <v-col cols="12">
              <v-chip-group active-class="neuter--text">
                <v-chip
                  v-for="chip in filtersChips"
                  :key="chip.value"
                  close
                  color="#FFFFFF"
                  small
                  @click:close="cleanFilter(chip)"
                >
                  <v-avatar left>
                    <v-icon small>mdi-filter-variant</v-icon>
                  </v-avatar>
                  {{ chip.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-toolbar>
        <!--FILTERS TOOLBAR *********************************************************************-->
      </template>

      <template v-slot:header.action="{ header }">
        <div class="d-flex justify-center align-center align-content-center text-center">
          <span>{{ header.text }}</span>
          <ExtraColumnsComponent />
        </div>
      </template>

      <template v-slot:item.statusLine="{ item }">
        <span class="dot-validated" v-if="item.status === 'VALIDATED'"/>
        <span class="dot-validating" v-if="item.status === 'VALIDATING'"/>
        <span class="dot-pending" v-if="item.status === 'PENDING'"/>
        <span class="dot-errored" v-if="item.status === 'ERRORED'" />
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip small v-if="item.status === 'VALIDATED'">{{ $t('records.filters.status.validated') }}</v-chip>
        <v-chip small v-if="item.status === 'PENDING'">{{ $t('records.filters.status.pending') }}</v-chip>
        <v-chip small v-if="item.status === 'VALIDATING'">{{ $t('records.filters.status.validating') }}</v-chip>
        <v-chip small v-if="item.status === 'ERRORED'">{{ $t('records.filters.status.errored') }}</v-chip>
      </template>

      <template v-slot:item.storage_type="{ item }">
        <v-chip small>{{ getStorageType(item.storage_device_type_id) }}</v-chip>
      </template>

      <template v-slot:item.description='{ item }'>
        <ReadMore
          v-if="item.description && translate(item.description) !== ''"
          :classes="'mb-0'"
          :text="translate(item.description)"
          :max-chars="10"
          :font-size="14"
          layout="dialog"
        />
        <span v-else>-</span>
      </template>

      <template v-slot:item.classificationType='{ item }'>
        {{ findClassification(item) }}
      </template>

      <template v-slot:item.color='{ item }'>
        <span v-if="item.color" :style="`border: 1px solid grey; display: block; width: 15px; height: 15px; background-color: ${item.color}`"></span>
        <span v-else>-</span>
      </template>

      <template v-slot:item.measurementUnit='{ item }'>
        {{ item.measurementUnit ? translate(item.measurementUnit) : '-' }}
      </template>

      <template v-slot:item.area='{ item }'>
        {{ item.area || '-' }}
      </template>

      <template v-slot:item.comercialSite='{ item }'>
        {{ item.comercialSite || '-' }}
      </template>

      <template v-slot:item.telephone='{ item }'>
        {{ item.telephone || '-' }}
      </template>

      <template v-slot:item.email='{ item }'>
        {{ item.email || '-' }}
      </template>

      <template v-slot:item.storageDeviceType='{ item }'>
        {{ translate(item.storageDeviceType) }}
      </template>

      <template v-slot:item.latitude='{ item }'>
        {{ item.latitude || '-' }}
      </template>

      <template v-slot:item.longitude='{ item }'>
        {{ item.longitude || '-' }}
      </template>

      <template v-slot:item.address1='{ item }'>
        <ReadMore
          v-if="item.address1"
          :classes="'mb-0'"
          :text="item.address1"
          :max-chars="10"
          :font-size="14"
        />
        <span v-else>-</span>
      </template>

      <template v-slot:item.address2='{ item }'>
        {{ item.address2 || '-' }}
      </template>

      <template v-slot:item.country='{ item }'>
        {{ item.country || '-' }}
      </template>

      <template v-slot:item.city='{ item }'>
        {{ item.city || '-' }}
      </template>

      <template v-slot:item.state='{ item }'>
        {{ item.state || '-' }}
      </template>

      <template v-slot:item.zipCode='{ item }'>
        {{ item.zipCode || '-' }}
      </template>

      <template v-slot:item.active="{ item }">
        <v-icon small class="mr-4" color="secondary" v-if="item.isActive">
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon small class="mr-4" color="neuter" v-if="!item.isActive">
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>

      <template v-slot:item.name="{ item }">
        <v-chip class="ma-1" small>{{ item.name }}</v-chip>
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                color="neuter"
                small
                class="mr-2"
                @click="showItem(item)"
                v-if="check([{ domain: 'Location', permisions: ['Read'] }])"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.show") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("menu.locations") }}
            </span>
          </span>
        </v-tooltip>
        <v-dialog
          v-model="manageAuthorizationDialog[item.id]"
          persistent width="600px"
        >
          <template v-slot:activator="{ on: dialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-icon
                  v-on="{ ...dialog, ...tooltip }"
                  color="neuter"
                  small
                  class="mr-2"
                  v-if="check([{ domain: 'Location', permisions: ['Validate'] }]) && item.status === 'PENDING'"
                >
                  mdi-map-marker-check-outline
                </v-icon>
              </template>
              <span>
                {{ $t('general.buttons.manageAuthorization') }}
                <span style="font-size: 12px; color: #aaaaaa">
                  {{ $t("menu.locations") }}
                </span>
              </span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title class="headline">{{ $t('general.buttons.manageAuthorization') }}</v-card-title>
            <v-card-text>
              <v-select
                :placeholder="$t('tokens.fields.walletAddress')"
                :items="statusItems"
                item-text="name"
                item-value="value"
                v-model="locationStatus"
                clearable
                small
                flat
                solo
                color="secondary"
                item-color="secondary"
                background-color="#EAEAEA80"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small text color="gray" @click="dialogAuthClose(item)">
                {{ $t("general.buttons.cancel") }}
              </v-btn>
              <v-btn small text color="secondary" @click="manageAuthorization(item)" :disabled="!locationStatus">
                {{ $t("general.buttons.confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                v-if="check([{ domain: 'Location', permisions: ['Update'] }])"
                color="neuter"
                class="mr-2"
                small
                @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.edit") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("locations.titles.locations") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                v-if="check([{ domain: 'Location', permisions: ['Delete'] }])"
                color="neuter"
                small
                @click="deleteItemDialog(item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.delete") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("locations.titles.locations") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-menu
              bottom
              left
              v-model='moreOptions[item.id]'
            >
              <template v-slot:activator='{ on:menu }'>
                <v-icon
                  v-on='menu'
                  class='mx-1'
                  color="neuter"
                  size='20'
                  @click='$set(moreOptions, item.id, !moreOptions[item.id])'
                >
                  mdi-dots-horizontal
                </v-icon>
              </template>
              <v-list elevation='0'>
                <v-list-item v-if="check([{ domain: 'Location', permisions: ['Validate'] }]) && item.status === 'PENDING'">
                  <template #default>
                    <ConfirmDialog
                      :show="!loadingAuth[item.id]"
                      :item="item"
                      :is-btn="true"
                      :title="item.status === 'VALIDATED' ? $t('general.buttons.unauthorize') : $t('general.buttons.authorize')"
                      :dialog-text="$t('locations.notification.changeStateConfirm')"
                      :ts-text="$t('menu.locations')"
                      :btn-title="item.status === 'VALIDATED' ? $t('general.buttons.unauthorize') : $t('general.buttons.authorize')"
                      :btn-icon="item.status === 'VALIDATED' ? 'mdi-map-marker-off-outline' : 'mdi-map-marker-outline'"
                      :w-icon="true"
                      :i-left="true"
                      :small="true"
                      color="neuter"
                      styles="mr-2"
                      action="validateItem"
                    >
                      <template v-slot:loading>
                        <v-progress-circular
                          v-show="loadingAuth[item.id]" size="12" class="mr-2" width="2" indeterminate />
                      </template>
                    </ConfirmDialog>
                  </template>
                </v-list-item>
                <v-list-item>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small text @click.stop='showVersions(item)' :disabled="item.versionNumber === 1">
                        <v-icon
                          color='black'
                          left
                          small
                        >
                          mdi-file-document-outline
                        </v-icon>
                        {{ $t('general.buttons.showVersions') }}
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('general.buttons.showVersions') }}
                      <span style="font-size: 12px; color: #aaaaaa">
                        {{ $t("menu.locations") }}
                      </span>
                    </span>
                  </v-tooltip>
                </v-list-item>
                <v-list-item v-if="ifPresentIntegration('IG') && check([{ domain: 'QrAdmin', permisions: ['Update'] }])">
                  <v-btn small text @click.stop='manageQR(item)'>
                    <v-icon
                      color='black'
                      left
                      small
                    >
                      mdi-qrcode-edit
                    </v-icon>
                    {{ $t('locations.titles.manageQr') }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <span>
            {{ $t('general.buttons.options') }}
            <span style="font-size: 12px; color: #aaaaaa">
              {{ $t('menu.records') }}
            </span>
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{ title }}</v-card-title>
          <v-card-text>{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="gray" @click="dialogClose">
              {{ $t("general.buttons.cancel") }}
            </v-btn>
            <v-btn small text color="secondary" @click="deleteItem">
              {{ $t("general.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--  SHOW VERSIONS  -->
      <VersionsComponent :dialog="showVersionsDialog"  @close:dialog="showVersionsDialog = $event" />
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import i18n from "@/plugins/i18n";
import {mapboxMixin} from "@/mixins/mapbox.mixin";
import {mapActions, mapGetters} from "vuex";
import { getByProperty } from "@/utils";
import MapBox from "@/components/vueMapbox/MapBox.vue";
import PermisionService from "../../../services/PermisionService";
import LanguajeService from "@/services/LanguajeService";
import VersionsComponent from "@/module/configuration/location/components/VersionsComponent.vue";
import ConfirmDialog from "../../../components/ConfirmDialog";
import ImportCSVComponent from "@/module/configuration/location/components/common/ImportCSVComponent.vue";
import DownloadCSVTemplateComponent
  from "@/module/configuration/location/components/common/DownloadCSVTemplateComponent.vue";
import MoreOptions from "@/components/MoreOptions.vue";
import ExtraColumnsComponent from "@/module/configuration/location/components/ExtraColumnsComponent.vue";
import ReadMore from "@/components/ReadMoreComponent.vue";
import DownloadErrorsComponent from "@/module/records/components/DownloadErrorsComponent.vue";
import EvidenceDialogComponent from "@/module/records/components/EvidenceDialogComponent.vue";

const FILTER_VALUES = [
  'template_names',
  'company_id',
  'process_ids',
  'products_ids',
  'seasons_ids',
  'person_in_charge_ids',
  'start_record_date_time',
  'end_record_date_time',
  'structure',
  'pageableDTO',
  'record_ids',
  'min_punctuation',
  'max_punctuation',
  'surveyStateType',
  'sdi_origin_ids',
  'sellers_date'
]

const KEYS = [
  'pageableDTO',
  'storage_device_types_ids',
  'minArea',
  'maxArea',
  'isActive',
  'status',
  'area',
  'stringFilter',
  'ids',
  'classificationTypes'
]

const PROFILE = JSON.parse(localStorage.getItem('profile'));

export default {
  components: {
    EvidenceDialogComponent, DownloadErrorsComponent,
    ReadMore,
    ExtraColumnsComponent,
    MoreOptions,
    DownloadCSVTemplateComponent,
    ImportCSVComponent,
    VersionsComponent,
    MapBox,
    ConfirmDialog
  },
  data: () => ({
    countryList: ['Brazil'],
    // dialogs
    dialog: false,
    showDialog: false,
    showVersionsDialog: false,
    loadingVersions: false,
    ready: false,
    title: "",
    dialogText: "",
    profile: null,
    max: 40,
    editedIndex: null,
    menu: false,
    moreOptions: {},
    editedItem: {
      id: null,
      name: "",
      gln: "",
      email: "",
      telephone: "",
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zipCode: "",
      document_key: "",
      document_value: "",
      location: null,
      description: "",
      measurementUnit: null,
      measurement_unit_id: null,
      area: null,
      active: true,
      storage_device_type_id: null,
      control_label: "",
      company_id: null,
      latitude: "",
      longitude: "",
      location_id: null
    },
    search: "",
    defaultItem: {},
    deletingItem: {},
    loadingExport: false,
    loadingAuth: {},
    selectedItems: [],
    showCheckBox: false,
    showItemDetailsDialog: false,
    areaSuffix: "",
    controls: {
      polygon: true,
      point: true,
      trash: true,
    },
    valid: false,
    turfDefaultUnit: null,
    selectedItem: {},
    options: {},
    manageAuthorizationDialog: {},
    locationStatus: null,
    statusItems: [
      { name: i18n.t('records.filters.status.validated'), value: "VALIDATED" },
      { name: i18n.t('records.filters.status.errored'), value: "ERRORED" }
    ],
    defaultFilters: {
      stringFilter: null,
      pageableDTO: {
        page: 0,
        size: 10,
        sortBy: 'name',
        direction: 'ASC',
      },
      isActive: null
    },
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [10, 20, 50, 100, 200],
    },
  }),

  mixins: [mapboxMixin],

  watch: {
    options: {
      async handler() {
        await this.searchText()
      },
      deep: true,
    },
  },

  mounted() {
    this.type = 'locations'
    this.$store.commit('ui/FILTERS_ORIGINAL_STATE')

    let filtersLocations = JSON.parse(localStorage.getItem('filtersLocations'))

    if (filtersLocations) {
      this.filtersObjects = filtersLocations.filtersObjects
        ? filtersLocations.filtersObjects
        : this.filtersObjects

      this.$root.$emit('set:locations:filters', { locations: filtersLocations.filtersObjects.locations.ids });

      this.filters.stringFilter = filtersLocations.filtersObjects.stringFilter && this.filtersObjects.stringFilter !== ''
        ? this.filtersObjects.stringFilter
        : null

      this.filters.isActive = typeof filtersLocations.filtersObjects.locations.isActive === 'boolean'
        ? filtersLocations.filtersObjects.locations.isActive
        : null

      this.filters.status =
        filtersLocations.filtersObjects.locations.status &&
        filtersLocations.filtersObjects.locations.status.length > 0
          ? this.filtersObjects.locations.status
          : []

      this.filters.storage_device_types_ids =
        filtersLocations.filtersObjects.storage_device_types_ids &&
        filtersLocations.filtersObjects.storage_device_types_ids.length > 0
          ? this.getByProperty(
            filtersLocations.filtersObjects.storage_device_types_ids,
            'storage_device_type_id'
          )
          : []

      this.filters.ids =
        filtersLocations.filtersObjects.locations.ids &&
        filtersLocations.filtersObjects.locations.ids.length > 0
          ? filtersLocations.filtersObjects.locations.ids
          : []

      this.filters.minArea = filtersLocations.filtersObjects.locations.area && filtersLocations.filtersObjects.locations.area[0] > 0
        ? filtersLocations.filtersObjects.locations.area[0]
        : 0
      this.$set(this.filtersObjects.locations.area, 0, this.areaRanges.primary[0] ? this.areaRanges.primary[0] : 0)

      this.filters.maxArea = filtersLocations.filtersObjects.locations.area && filtersLocations.filtersObjects.locations.area[1] >= 1
        ? filtersLocations.filtersObjects.locations.area[1]
        : 0
      this.$set(this.filtersObjects.locations.area, 1, this.areaRanges.primary[1] ? this.areaRanges.primary[1] : 0)

      // this.filtersChips =
      //   filtersLocations.filtersChips && filtersLocations.filtersChips.length > 0
      //     ? filtersLocations.filtersChips
      //     : []
      // this.cantFilters =
      //   filtersLocations.cantFilters && filtersLocations.cantFilters.length > 0
      //     ? filtersLocations.cantFilters
      //     : null
    }

    this.$root.$on('setFiltersLocations', () => {
      this.closeFilterDialog()
    })

    this.$root.$on('validateItem', (item) => {
      this.validateItem(item)
    })
  },

  computed: {
    ...mapGetters({
      locations: 'locations/locationsList',
      totalFromServer: "locations/totalFromServer",
      areaRanges: 'ui/GET_LOCATION_RANGES',
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules",
      // countryList: "general/countryList",
      onlyPositiveNumberFormat: "general/onlyPositiveNumberFormat",
      equalObjectsCompareRule: "general/equalObjectsCompareRule",
      classifications: "locations/GET_CLASSIFICATIONS",
      //----ui-----------------------------------------
      headers: "locations/GET_HEADERS",
      selectedColumns: "locations/GET_SELECTED_COLUMNS",
      ifPresentIntegration: "integration/IS_PRESENT",
    }),
    computedFilters() {
      return _.pick(this.filters, KEYS)
    },
    type: {
      get() {
        return this.$store.getters['ui/GET_FILTER_TYPE']
      },
      set(val) {
        this.$store.dispatch('ui/SET_FILTER_TYPE', val)
      },
    },
    drawer: {
      get() {
        return this.$store.getters['ui/GET_DRAWER']
      },
      set(val) {
        this.$store.dispatch('ui/SET_DRAWER', val)
      },
    },
    cantFilters: {
      get() {
        return this.$store.getters['ui/GET_COUNT_FILTERS']
      },
      set(val) {
        this.$store.dispatch('ui/SET_COUNT_FILTERS', val)
      },
    },
    loading: {
      get() {
        return this.$store.getters['authority/loading']
      },
      set(val) {
        this.$store.commit('authority/SET_LOADING_OFF', val)
      },
    },
    config: {
      get() {
        return this.$store.getters['general/config']
      },
      set(val) {
        this.$store.commit('general/SET_CONFIG', val)
      }
    },
    filtersObjects: {
      get() {
        return this.$store.getters['ui/GET_OBJECTS_TO_FILTERS']
      },
      set(val) {
        this.$store.dispatch('ui/SET_OBJECTS_TO_FILTERS', val)
      },
    },
    filters: {
      get() {
        return this.$store.getters['ui/GET_FILTERS']
      },
      set(val) {
        this.$store.dispatch('ui/SET_FILTERS', val)
      },
    },
    filtersChips: {
      get() {
        return this.$store.getters['ui/GET_FILTERS_CHIPS']
      },
      set(val) {
        this.$store.dispatch('ui/SET_FILTERS_CHIPS', val)
      },
    },
    decimalFormat() {
      return this.config.company.traceability.structure.number.afterPoint !== null
          ? this.config.company.traceability.structure.number.afterPoint
          : 3
    },
    formTitle() {
      return (
          (this.editedIndex === null
              ? i18n.t("general.titles.new")
              : i18n.t("general.titles.edit")) +
          " " +
          i18n.t("locations.titles.new_location")
      );
    },
  },
  async created() {
    this.$store.commit('ui/SET_FILTERS_SORT', []);
    // this.$store.commit('locations/RESTORE_LOCATIONS_LIST')

    await this.fetchAreaRange();
    await this.$root.$emit('filter:fetch-locations');

    this.config = JSON.parse(localStorage.getItem('config'))
    await this.initialize();
  },

  methods: {
    ...mapActions({
      saveLocations: "locations/saveLocations",
      fetchListLocations: "locations/fetchFilteredLocations",
      fetchLocationById: "locations/fetchLocationById",
      deleteLocation: "locations/deleteLocation",
      changeActiveValue: "locations/changeActiveValue",
      exportCSV: "locations/exportCSV",
      authorizeLocationOrNot: "locations/authorizeLocationOrNot",
      fetchAllUnits: "units/fetchAllUnits",
      fetchStorageTypesActiveByCompany: "storage_type/fetchStorageTypesActiveByCompany",
      fetchExtraColumns: 'locations/fetchExtraColumns',
      fetchAreaRange: "ui/fetchPrimaryRange",
      fetchListMapLocations: "authority/getLocationsByAuthority"
    }),
    findClassification(item) {
      const classification = _.find(this.classifications, { id: item.classificationType });

      if (classification) {
        return this.translate(classification.languageKey);
      } else {
        return '-';
      }
    },
    mapDataReload(){
      this.fetchListMapLocations([{
        userId: PROFILE.id,
        companyId: PROFILE.company_id,
        isActive: true
      }, this.$toast])
    },
    manageQR(item) {
      const token = localStorage.getItem('token');
      window.open(
        encodeURI(`${this.profile.qrDomain}/admin/producer?pl=${item.locationId}&type=qrp&token=${token}`),
        '_blank'
      );
    },
    async cleanFilter(chip) {
      let tag = chip.value
      let filtersLocations = JSON.parse(localStorage.getItem('filtersLocations'))
      if (this.filters[tag]) {
        if (this.filtersObjects[tag]) {
          filtersLocations.filtersObjects[tag] = []
          this.$set(this.filtersObjects, tag, [])
        }
        this.$set(this.filters, tag, [])
      }

      if (tag === 'isActive') {
        this.filters[tag] = null
        this.filtersObjects.locations[tag] = null
        filtersLocations.filtersObjects.locations[tag] = null
      }

      if (tag === 'stringFilter') {
        this.filters[tag] = null
        this.filtersObjects[tag] = null
      }

      if (tag === 'status') {
        this.filters[tag] = []
        this.filtersObjects.locations[tag] = []
        filtersLocations.filtersObjects.locations[tag] = []
      }

      if (tag === 'classificationTypes') {
        this.$set(this.filters, tag, [])
        this.$set(this.filtersObjects.locations, tag, [])

        filtersLocations.filtersObjects.locations[tag] = []
      }

      if (tag === 'minArea') {
        this.filters[tag] = null
        this.$set(this.filtersObjects.locations.area, 0, this.areaRanges.primary[0] ? this.areaRanges.primary[0] : 0)
        filtersLocations.filtersObjects.locations.area[0] = this.areaRanges.primary[0] ? this.areaRanges.primary[0] : 0
      }

      if (tag === 'maxArea') {
        this.filters[tag] = null
        this.$set(this.filtersObjects.locations.area, 1, this.areaRanges.primary[1] ? this.areaRanges.primary[1] : 0)
        filtersLocations.filtersObjects.locations.area[1] = this.areaRanges.primary[1] ? this.areaRanges.primary[1] : 0
      }

      if (tag === 'ids') {
        this.$set(this.filters, tag, [])
        this.$set(this.filtersObjects.locations, tag, [])
        filtersLocations.filtersObjects.locations[tag] = []

        this.$root.$emit("clear:locations:filters", {
          locations: [],
          storageDevice: []
        })
      }

      localStorage.setItem('filtersLocations', JSON.stringify(filtersLocations))
      await this.applyFilters()
      this.filteredChips()
      this.getCantFilters()
    },
    translate(key, uppercase) {
      return LanguajeService.getKey3(key)
    },
    getStorageType(id) {
      const storageType = this.$store.getters['storage_type/getStorageTypeById'](id)
      if (storageType)
        return this.translate(storageType.storage_device_type_key);
      else
        return "N/A";
    },
    async applyFilters() {
      this.loading = true;
      let filtersLocations = JSON.parse(localStorage.getItem('filtersLocations'))
      if (filtersLocations) {
        this.filters.stringFilter = filtersLocations.filtersObjects.stringFilter && this.filtersObjects.stringFilter !== ''
          ? this.filtersObjects.stringFilter
          : null

        this.filters.isActive = typeof filtersLocations.filtersObjects.locations.isActive === 'boolean'
          ? filtersLocations.filtersObjects.locations.isActive
          : null

        this.filters.status =
          filtersLocations.filtersObjects.locations.status &&
          filtersLocations.filtersObjects.locations.status.length > 0
            ? this.filtersObjects.locations.status
            : []

        this.filters.classificationTypes =
          filtersLocations.filtersObjects.locations.classificationTypes &&
          filtersLocations.filtersObjects.locations.classificationTypes.length > 0
            ? getByProperty(
              filtersLocations.filtersObjects.locations.classificationTypes,
              'id'
            )
            : []

        this.filters.storage_device_types_ids =
          filtersLocations.filtersObjects.storage_device_types_ids &&
          filtersLocations.filtersObjects.storage_device_types_ids.length > 0
            ? this.getByProperty(
              filtersLocations.filtersObjects.storage_device_types_ids,
              'storage_device_type_id'
            )
            : []

        this.filters.ids =
          filtersLocations.filtersObjects.locations.ids &&
          filtersLocations.filtersObjects.locations.ids.length > 0
            ? filtersLocations.filtersObjects.locations.ids
            : []

        this.filters.minArea = filtersLocations.filtersObjects.locations.area && filtersLocations.filtersObjects.locations.area[0] > 0
          ? filtersLocations.filtersObjects.locations.area[0]
          : 0

        this.filters.maxArea = filtersLocations.filtersObjects.locations.area && filtersLocations.filtersObjects.locations.area[1] >= 1
          ? filtersLocations.filtersObjects.locations.area[1]
          : 0
      }

      const direction =
        this.options.sortDesc.length === 0 || this.options.sortDesc[0]
          ? 'desc'
          : 'asc'

      const sort =
        this.options.sortBy.length === 0
          ? "name"
          : _.filter(this.headers(this.selectedColumns), { value: this.options.sortBy[0] })[0].val;

      this.$set(this.filters, 'pageableDTO', {
        page: this.options.page > 0 ? this.options.page - 1 : 0,
        size: this.options.itemsPerPage > -1 ? this.options.itemsPerPage : this.totalFromServer,
        sortBy: sort,
        direction: direction,
      })

      await this.fetchListLocations([_.pick(this.filters, KEYS), this.$toast]).finally(() => {
        this.loading = false
      })
    },
    async closeFilterDialog() {
      this.search = "";
      await this.applyFilters()
      this.filteredChips()
      this.getCantFilters()
    },
    async openFilters() {
      this.type = 'locations'

      let filtersLocations = JSON.parse(localStorage.getItem('filtersLocations'))

      this.$root.$emit("set:locations:filters", { locations: filtersLocations.filtersObjects.locations.ids, storageDevices: [] })

      this.drawer = true
    },
    filteredChips() {
      let chips = []
      const filters = _.pick(this.filters, KEYS)

      _.forEach(filters, (value, key) => {
        if (
          ![FILTER_VALUES, 'stringFilter', 'ids', 'status', 'classificationTypes'].includes(key) &&
          this.filters[key] !== null &&
          this.filters[key] !== undefined &&
          this.filters[key].length > 0
        ) {
          chips.push({
            value: key,
            name: _.filter(this.headers(this.selectedColumns), { val: key })[0].text,
          })
        } else {
          if (key === 'ids' && value && value.length > 0) {
            chips.push({
              value: key,
              name: i18n.t("reports.fields.location"),
            })
          }

          if (key === 'status' && (value && value.length > 0)) {
            chips.push({
              value: key,
              name: i18n.t("records.fields.status"),
            })
          }

          if (key === 'classificationTypes' && (value && value.length > 0)) {
            chips.push({
              value: key,
              name: i18n.t("locations.fields.classification"),
            })
          }

          if (key === 'stringFilter' && value && value !== '') {
            chips.push({
              value: key,
              name: i18n.t("general.fields.text"),
            })
          }

          if (key === 'minArea' && value > this.areaRanges.primary[0]) {
            chips.push({
              value: key,
              name: 'Min ' + i18n.t("locations.fields.area"),
            })
          }

          if (key === 'maxArea' && (value >= 1 && value < this.areaRanges.primary[1])) {
            chips.push({
              value: key,
              name: 'Max ' + i18n.t("locations.fields.area"),
            })
          }

          if (typeof this.filters.isActive === 'boolean' && key === 'isActive') {
            chips.push({
              value: key,
              name: _.filter(this.headers(this.selectedColumns), { val: key })[0].text,
            })
          }
        }
      })

      this.filtersChips = chips
    },
    getCantFilters() {
      const filters = _.pick(this.filters, KEYS)
      let cant = 0
      _.forEach(filters, (value, key) => {
        if (![...FILTER_VALUES, 'status', 'classificationTypes', 'isActive', 'area', 'minArea', 'maxArea', 'stringFilter', 'ids'].includes(key) && value !== null) {
          cant += value.length
        }

        cant +=
          ((key === 'minArea' && value > this.areaRanges.primary[0]) || (key === 'maxArea' && (value >= 1 && value < this.areaRanges.primary[1])))
            ? 1
            : 0

        cant +=
          (key === 'status' && (value && value.length > 0))
            ? 1
            : 0

        cant +=
          (key === 'classificationTypes' && (value && value.length > 0))
            ? 1
            : 0

        cant +=
          (key === 'isActive' && typeof value === 'boolean')
            ? 1
            : 0

        cant +=
          (key === 'stringFilter' && value && value !== '')
            ? 1
            : 0

        cant +=
          (key === 'ids' && (value && value.length > 0))
            ? 1
            : 0
      })
      this.cantFilters = cant
    },
    async manageAuthorization(item) {
      this.$set(this.loadingAuth, item.id, true);

      await this.authorizeLocationOrNot([{
        id: item.id,
        status: this.locationStatus
      }, this.$toast])
        .finally(() => {
          this.$set(this.loadingAuth, item.id, false);
          this.manageAuthorizationDialog[item.id]  = false
        })
    },
    async validateItem(item) {
      this.$set(this.loadingAuth, item.id, true);

      await this.authorizeLocationOrNot([{
          id: item.id,
          status: item.status === 'VALIDATED' ? 'ERRORED' : 'VALIDATED'
        }, this.$toast])
        .finally(() => {
          this.$set(this.loadingAuth, item.id, false);
        })
    },
    async searchAll() {
      this.loading = true

      await this.fetchListLocations([this.filters, this.$toast]).finally(() => {
        this.loading = false
      })
    },
    async searchForText() {
      this.options.page = 1
      await this.searchText()
    },
    async searchText() {
      this.loading = true

      let filters = {
        ...this.filters,
        stringFilter: this.search,
        pageableDTO: {
          page: 0,
          size: 10,
          sortBy: 'name',
          direction: 'DESC'
        },
        isActive: null
      }

      const direction =
        this.options.sortDesc.length === 0 || this.options.sortDesc[0]
          ? 'asc'
          : 'desc'
      const sort =
        this.options.sortBy.length === 0
          ? 'id'
          : _.filter(this.headers(this.selectedColumns), { value: this.options.sortBy[0] })[0].value
      filters.pageableDTO = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage,
        sortBy: sort,
        direction: direction,
      }

      if (this.search && this.search !== '') {
        await this.fetchListLocations([filters, this.$toast])
      } else {
        await this.closeFilterDialog()
      }

      this.loading = false
    },
    async initialize() {
      this.profile = JSON.parse(localStorage.getItem("profile"));

      await this.fetchExtraColumns();

      // await this.fetchListLocations([this.filters, this.$toast])

      await this.fetchStorageTypesActiveByCompany([this.$toast]);
      await this.fetchAllUnits();
    },
    checkBoxShow() {
      this.showCheckBox = !this.showCheckBox;

      if (!this.showCheckBox) this.selectedItems = [];
    },
    getByProperty(array, property) {
      return _.map(array, property);
    },
    async exportCSVLocations() {
      this.loadingExport = true;
      const data = {
        language: LanguajeService.getLenguajeName(),
        versionIds: this.getByProperty(this.selectedItems, "id"),
        ...this.filters
      };
      await this.exportCSV([data, this.$toast]).finally(() => {
        this.loadingExport = false;
      });
    },
    activeDeactivate(item) {
      this.getItem(item);
      this.editedItem.active = !this.editedItem.active;
      this.changeActiveValue([item.id, !item.active, this.$toast]).finally(
          () => {
            this.fetchListLocations([this.defaultFilters, this.$toast]);
          }
      );
    },
    getItem(item) {
      this.editedIndex = this.locations.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    mgmtLocation() {
      localStorage.setItem('location_editOrSave', null)
      this.$router.push({ name: 'location-management', params: { action: 'new' } })
    },
    async editItem(item) {
      localStorage.setItem('location_editOrSave', item.id)
      localStorage.setItem('location:isVersion', JSON.stringify(0));

      await this.$router.push({ name: 'location-management', params: { uuid: item.id, action: 'edit' } })
    },

    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("general.titles.alert"),
        dialogText: i18n.t("general.notification.deleteItems"),
      });
    },
    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },
    deleteItem() {
      this.dialogClose();
      this.deleteLocation([this.deletingItem.id, this.$toast]).finally(() => {
        this.fetchListLocations([{...this.filters, stringFilter: this.search || null}, this.$toast]);
        this.mapDataReload()
      });
    },
    dialogClose() {
      this.showDialog = false;
    },
    dialogAuthClose(item) {
      this.manageAuthorizationDialog[item.id] = false;
      this.locationStatus= null
    },
    close() {
      this.$refs.form.reset();
      if (this.showItemDetailsDialog)
        this.showItemDetailsDialog = false;
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = null;
      }, 300);
    },
    check(permisions) {
      return PermisionService.check(permisions);
    },
    showItem(item) {
      this.$router.push({ name: 'location-management', params: { uuid: item.id, action: 'show' } })
    },
    async showVersions(item) {
      this.showVersionsDialog = true
      this.$root.$emit('location:versions', item)
    },
    closeVersionDialog() {
      this.showVersionsDialog = false;
    },
    saveShow() {
      if (
          this.equalObjectsCompareRule(this.selectedItem, this.editedItem)
      ) {
        this.$toast.error(i18n.t("general.notification.nothingToModify"), {
          queueable: true,
          color: "secondary",
        });
      } else {
        let item = Object.assign({}, this.editedItem);
        let location = null;
        if (this.IsJsonString(this.editedItem.location)) {
          location = JSON.parse(this.editedItem.location);
        }
        item.location = JSON.stringify(location);
        item.storage_device_type_id =
            item.storage_device_type_id.storage_device_type_id;
        item.measurement_unit_id = this.turfDefaultUnit.id;
        item.company = {id: this.profile.company_id};
        item.longitude = 1;
        item.latitude = 1;

        const document = {
          documentAuthority: {id: 1},
          documentType: {id: 1},
          documentDescription: {id: 1},
          company: {id: item.company.id},
        }

        this.saveLocations([
          item,
          this.editedIndex,
          document,
          null,
          document,
          null,
          this.$toast,
        ]).then(res => {
          if (res) {
            this.close();
            this.fetchListLocations([this.defaultFilters, this.$toast]);
          }
        })
      }
    },
    updateCountry(country) {
      this.selectedItem.country = country;
      this.editedItem.country = country;
    },
    updatePersonInChargeIds(editedItem) {
      this.selectedItem.personInChargeIds = editedItem.personInChargeIds
    },
    updateActive(active) {
      this.selectedItem.active = active;
      this.editedItem.active = active;
    },
    replace(event) {
      event.currentTarget.value = event.currentTarget.value.toString().replaceAll('"', '')
    },
    replaceSearch(event) {
      this.search = event.currentTarget.value.toString().replaceAll('"', '')
    },
  },

  destroyed() {
    this.$root.$off('setFiltersLocations')
    this.$root.$off('validateItem')

    this.$root.$emit("clear:locations:filters", {
      locations: [],
      storageDevice: []
    })

    this.$store.commit('ui/FILTERS_OBJECTS_ORIGINAL_STATE');
  }
};
</script>

<style scoped>
.v-data-table {
  margin: 25px;
}

>>> .mapboxgl-map canvas {
  border-radius: 8px !important;
}
:deep(.v-chip-group .v-slide-group__content) {
  display: flex !important;
  justify-content: flex-start !important;
}
:deep(.v-toolbar__content) {
  padding: 0 !important;
}
</style>
